
import React from 'react';
import Text from '../../common/text.component';
import PropTypes from 'prop-types';

const COMPANY_NAME = 'Carmuv';


const Copyright = (props) => {
  return (
    <Text g300>
      {`© ${props.companyName} ${new Date().getFullYear()}`}
    </Text>
  );
};

Copyright.propTypes = {
  companyName: PropTypes.string,
};

Copyright.defaultProps = {
  companyName: COMPANY_NAME,
};


export default Copyright;

