/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/text.component';
import SvgIcon from '../../common/svg-icon.component';
import { default as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { default as InstagramIcon } from '../../../assets/icons/instagram.svg';

const TITLE_MESSAGE = 'Seguinos en nuestras redes';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  iconContainer: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: theme.spacing(2),
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize:45,
    color: theme.palette.grayScale.g300,
  },
  iconsTitle: {
    fontWeight: 600,
    fontSize: 'small',
    color: theme.palette.grayScale.g300,
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    }
  }
}));

const SocialMediaButtons = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <Grid item >
        <Text g300>{TITLE_MESSAGE}</Text>
      </Grid>
      <Grid item  >
        <Buttons ></Buttons>
      </Grid>
    </Grid>
  );
};

const Buttons = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        direction='row'
        className={classes.iconContainer}
      >
        <a href='https://www.facebook.com/carmuvautos/' rel="noreferrer" target="_blank">
          <SvgIcon className={classes.icon} component={FacebookIcon}></SvgIcon>
        </a>
        <a href='https://www.instagram.com/carmuv_autos/' rel="noreferrer" target="_blank">
          <SvgIcon className={classes.icon} component={InstagramIcon}></SvgIcon>
        </a>
      </Grid >
    </div>
  );
};

export default SocialMediaButtons;
