import React from 'react';
import Link from '../../common/Link.component';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Text from '../../common/text.component';
import { useAppContext } from '../../../context/AppContext';
import { filterLinks } from '../../../utils/utils';

const FOOTER_BUTTON_ID = {
  'Comprá': 'home-footer-btn-compra',
  'Vendé': 'home-footer-btn-vende',
  'Cotizador': 'home-footer-btn-cotizador',
  'Catálogo': 'home-footer-btn-catálogo',
  'Blog': 'home-footer-btn-blog ',
};

const usesStyles = makeStyles((theme) => ({
  item: {
    cursor:'initial'
  },
  itemTitleContainer: {
    paddingBottom: theme.spacing(1)
  },
  subItemContainer: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'colmn',
      alignItems: 'center',
    },
    gap: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: 10,
    },
  },
  subItem: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '.022%',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 4,
      marginRight: 4
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '100%'
    },
  },
  subItemSeparator: {
    [theme.breakpoints.down('sm')]: {
      borderRightColor: theme.palette.grayScale.g300,
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      paddingRight: '14px',
    },
  }
}));

const MenuItem = (props) => {
  const classes = usesStyles();
  if (props.item) {
    return (
      <Grid container
        direction="column"
        className={classes.item}
        justify='flex-start'>
        <Box className={classes.itemTitleContainer}>
          <Text bold g300>{props.item.name}</Text>
        </Box>
        <Grid container item className={classes.subItemContainer} >
          <SubItems subItems={props.item.subItems} isLoggedIn={props.isLoggedIn} />
        </Grid>
      </Grid>
    );
  }
};

const SubItems = ( { subItems, isLoggedIn } ) => {
  const { user } = useAppContext()
  const { role_name } = user.profile

  if (subItems) {
    const filteredSubItems = filterLinks(subItems, isLoggedIn, role_name)
    const filteredSubItemsLength = filteredSubItems.length;
    return (
      filteredSubItems
        .map(
          (subItem, index) => {
            const isLastElement = (filteredSubItemsLength - 1) === index;
            return (
              <SubItem
                key={index}
                data-test-id='footer-menu-sub-item'
                subItem={subItem}
                isLastElement={isLastElement} />
            );
          }
        )
    );
  }
  return null;
};

const SubItem = (props) => {
  const classes = usesStyles();
  return (
    <Grid item md={10} lg={9}>
      <Link
        light
        to={props.subItem.link}
        className={clsx(classes.subItem, { [classes.subItemSeparator]: !props.isLastElement })}
        id={FOOTER_BUTTON_ID[props.subItem.name]}
      >
        {props.subItem.name}
      </Link>
    </Grid >
  );
};

export default MenuItem;
