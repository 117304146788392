import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid } from '@material-ui/core';
import Text from '../common/text.component';
import Link from '../common/Link.component';

const useStyles = makeStyles(theme => ({
  menuItem: {
    paddingBottom: theme.spacing(2),
  },
  hidden: {
    visibility: 'hidden',
  },
  item: {
    cursor:'initial',
    width:'115%',
    [theme.breakpoints.down('sm')]: {
      width:'initial'
    },
  },
  itemTitleContainer: {
    paddingBottom: theme.spacing(1)
  },
  subItemContainer: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'colmn',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  subItemSeparator: {
    [theme.breakpoints.down('sm')]: {
      borderRightColor: theme.palette.grayScale.g300,
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
    },
  },
  divider:{
    backgroundColor:'white',
    height: '1.25rem',
    margin:'auto 0.5rem',
  }
}));


const FooterLogin = ( { item, isLoggedIn } ) => {
  const classes = useStyles();
  const visibility = isLoggedIn ? 'hidden' : 'menuItem';
  return (
    <Grid item xs={12} md={3} className={classes[visibility]}>
      <Grid container
        direction="column"
        className={classes.item}
        justify='flex-start'>
        <Box className={classes.itemTitleContainer}>
          <Text bold g300>{item.name}</Text>
        </Box>
        <Grid container item className={classes.subItemContainer} >
          <Grid item>
            <Link
              light
              to={item.subItems[0].link}
              className={classes.subItem}>
              {item.subItems[0].name}
            </Link>
          </Grid>
          <Grid >
            <Divider orientation='vertical' flexItem variant='fullWidth' className={classes.divider} />
          </Grid>
          <Grid>
            <Link
              light
              to={item.subItems[1].link}
              className={classes.subItem}>
              {item.subItems[1].name}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterLogin;
