/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Copyright from './Copyright.component';
import SocialMediaButtons from './social-media-buttons.component';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FooterDecorator from '../footer-decorator.component';
import Link from '../../common/Link.component';
import Box from '@material-ui/core/Box';

const TERMS_LINK_TEXT = 'Términos y condiciones';
const POLICY_LINK_TEXT = 'Políticas de privacidad';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding:`${theme.spacing(2)}px 0`,
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  socialMediaButtons: {
    [theme.breakpoints.down('sm')]: {
      padding:`${theme.spacing(2)}px 0`
    },
  },
  termsAndPolicy: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

  },
  copyright: {
    [theme.breakpoints.down('sm')]: {
      borderTopColor: theme.palette.grayScale.g200,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      fontSize: 'big',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1.2)
    },
  },
  policyLink: {
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: 'big',
      paddingLeft: theme.spacing(1.2)
    },
  }
}));

const ContactInfo = ({ isLoggedIn, termsAndPolicy, ...props }) => {
  const classes = useStyles();
  const termsLink = isLoggedIn ? termsAndPolicy.loggedInTermsLink : termsAndPolicy.termsLink;
  return (
    <Grid container item direction='column'>
      <FooterDecorator />
      <Grid container item className={classes.container} >
        <Box clone order={{ sm: 1, md:4 }}>
          <Grid item xs={12} md={4} className={classes.socialMediaButtons}>
            <SocialMediaButtons />
          </Grid>
        </Box>
        <Box clone order={{ sm: 2 }} display="flex" justifyContent="center" className={classes.termsAndPolicy}>
          <Grid item xs={12} md={5}>
            <Box className={classes.copyright}>
              <Link light href={termsLink} target="_blank" rel="noreferrer" download>{TERMS_LINK_TEXT}</Link>
            </Box>
            <Box className={classes.policyLink}>
              <Link light href={termsAndPolicy.policyLink} target="_blank" rel="noreferrer" download>{POLICY_LINK_TEXT}</Link>
            </Box>
          </Grid>
        </Box>

        <Box clone order={{ sm: 4, md:1 }}>
          <Grid item xs={12} md={3} className={classes.copyright}>
            <Copyright data-test-id='footer-copy-right' companyName={props.companyName}/>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};


ContactInfo.propTypes = {
  companyName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  termsAndPolicy: PropTypes.object,
};

export default ContactInfo;
