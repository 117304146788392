import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from './menu-item.component';
import { makeStyles } from '@material-ui/core/styles';
import FooterLogin from '../footer-login.component';


const usesStyles = makeStyles((theme) => ({
  menuItem: {
    paddingBottom: theme.spacing(2),
  },
}));
const FooterMenu = ( { isLoggedIn, menuLinks } ) => {
  const classes = usesStyles();
  const lastItem = menuLinks.length - 1;
  return (
    <Grid container direction='row'>
      {menuLinks.map((menuLink, index) =>
        lastItem === index ?
          <FooterLogin item={menuLink} isLoggedIn={isLoggedIn}/>
          :
          <Grid item xs={12} md={3} key={index} className={classes.menuItem}>
            <MenuItem key={index} data-test-id='footer-menu-item' item={menuLink} isLoggedIn={isLoggedIn}></MenuItem>
          </Grid>
      )}
    </Grid >
  );
};


export default FooterMenu;
