/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from '../common/logo.component';
import GrupoTagle from '../../assets/icons/grupo-tagle.svg';
import ContactInfo from './contact-info/contact-info.component';
import FooterMenu from './menu/FooterMenu.component';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Link from '../common/Link.component';

const useStyles = makeStyles((theme) => ({
  primarySection: {
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },

  },
  logoContainer: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      bottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      position: 'relative',
      right: theme.spacing(1)
    },
    '& #secondaryLogo': {
      position: 'absolute',
      top: '6rem',
      left: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        left: 'initial',
        right: '0',
        top: '0.8rem',
      },
    }
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '3.125rem',
    }
  },
  loginContainer: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  menuContainer: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  contactInfo: {
    order: 4,
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      order: 4,
    },
    display:'block'
  },
  footerContainer: {
    background: theme.palette.secondary.main,
    zIndex:99,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.space.horizontal.mobile}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)}px ${theme.space.horizontal.desktop}`
    },
  }
}));


const Footer = ( { menuLinks, termsAndPolicy, isLoggedIn } ) => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.footerContainer}>
      <Grid container item className={classes.primarySection}>
        <Grid container item xs={12} md={3} lg={5} className={classes.logoContainer}>
          <Link to='/' className={classes.logo}>
            <Logo className={classes.logo} white />
          </Link>
            <GrupoTagle id='secondaryLogo'/>
        </Grid>
        <Grid container item xs={12} md={9} lg={7} className={classes.menuContainer}>
          <FooterMenu menuLinks={menuLinks} isLoggedIn={isLoggedIn} />
        </Grid>
      </Grid>
      <Grid container item className={classes.contactInfo}>
        <ContactInfo isLoggedIn={isLoggedIn} termsAndPolicy={termsAndPolicy}/>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  menuLinks: PropTypes.array,
};

Footer.defaultProps = {
  menuLinks: [],
};

export default Footer;
