import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import Link from '../common/Link.component';
import clsx from 'clsx';

const getBreadCrumbsColor = (theme, color, capitalizeCrumbs, colorHover) => {
  return {
    textTransform: capitalizeCrumbs ? 'none' : 'capitalize',
    color,
    fontSize: theme.typography.size.small,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.size.smaller,
      fontWeight: 400
    },
    '&:hover':{
      color: colorHover || '#000000'
    }
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(3)}px`,
      paddingTop: theme.spacing(3)
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.space.horizontal.desktop}`
    },
  },
  sticky: {
    [theme.breakpoints.down('sm')]: {
      position:'sticky',
      top:'35px',
      zIndex: 999,
      backgroundColor:'white',
    }
  },
  link: ({ capitalizeCrumbs }) => getBreadCrumbsColor(theme, theme.palette.secondary.main, capitalizeCrumbs),
  linkHome: getBreadCrumbsColor(theme, theme.palette.primary.main, null, theme.palette.primary.dark),
  active: {
    color: theme.palette.secondary.main,
  },
  separator: {
    fontSize: theme.typography.size.xLarge,
    margin: theme.spacing(1),
    position: 'relative',
    top: '0.4375rem',
    [theme.breakpoints.up('sm')]: {
      top: '0.375rem',
    }
  },
  crumbRoot: {
    display: 'inline',
  },
  ellipsis: {
    marginLeft:'1.125rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap'
  }
}));
const pathsWithStickySubHeader = [
  '/cotiza/'
]
const SubHeader = ({ pageContext, children, className, capitalizeCrumbs }) => {
  const classes = useStyles();

  const {
    breadcrumb: { crumbs }
  } = pageContext;
  const gridMdWidth = children ? 6 : 12;
  const isSticky = crumbs.some((crumb)=>pathsWithStickySubHeader.includes(crumb.pathname));
  return (
    <Grid container className={clsx(classes.root, { [classes.sticky]: isSticky }, className)}>
      <Grid item xs={12} md={gridMdWidth}>
        <BreadCrumbs crumbs={crumbs} capitalizeCrumbs={capitalizeCrumbs}/>
      </Grid>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};

const BreadCrumbs = ({ crumbs, capitalizeCrumbs }) => {
  const classes = useStyles({ capitalizeCrumbs });
  return (
    <div className={classes.ellipsis}>
      {crumbs.map((crumb, index) => {
        return (
          <div className={classes.crumbRoot} key={crumb.pathname}>
            <Link
              to={crumb.pathname}
              className={index === 0 ? classes.linkHome : classes.link}
              activeStyle={{ color: 'black' }}
            >
              {crumb.crumbLabel}
            </Link>
            {(crumbs.length !== index + 1) && (<span className={classes.separator}>&#183;</span>)}
          </div>
        );
      })}
    </div>
  );
};

SubHeader.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default SubHeader;
