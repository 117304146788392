/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Header from '../header/Header.component';
import SEO from '../seo';
import Footer from '../footer/footer.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_AUTH } from '../../queries/getUserAuth';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/style.css';
import SubHeader from '../subHeader/SubHeader.component';
import { isAdmin } from '../../../src/api/authorization';
import { checkIfWasLoggedIn } from '../../utils/hooks/useIsLoggedUser';
import { useAppContext } from '../../context/AppContext';
import setUserData from '../../context/user/actions/setUserData';


const usesStyle = makeStyles((theme) => ({
  background: {
    minHeight: '100vh',
    backgroundColor: theme.palette.grayScale.g0
  },
  main: {
    margin: '0 auto',
  },
  subHeader: {
    marginTop: '3.9rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
      paddingLeft: theme.spacing(3)
    },
  }
})
);

export const PureLayout = ({
  title,
  meta,
  description,
  renderData,
  showSubHeader,
  location,
  isLoginPage,
  pageContext,
  subHeaderChildren,
  children,
  showMobileBackIcon,
  onGoBack,
  capitalizeCrumbs = false,
  hideMobileUserMenu
}) => {
  const { userDispatch } = useAppContext();
  const { data } = useQuery(GET_USER_AUTH, { fetchPolicy: 'network-only',onCompleted: (authData)=>checkIfWasLoggedIn((authData && authData.currentUser !== null)) });
  const { profile } = data?.currentUser || {};
  const isLoggedIn = (data && data.currentUser !== null) || false;
  const isAdminUser = (isLoggedIn && isAdmin(data.currentUser));
  const classes = usesStyle();
  useEffect(() => {
    setUserData(userDispatch, { profile, isLoggedIn });
  }, [isLoggedIn, profile, userDispatch]);

  return (
    <Grid container className={classes.background}>
      <SEO title={title} meta={meta} description={description} />
      <Header
        siteTitle={renderData.site.siteMetadata.title}
        menuLinks={renderData.site.siteMetadata.menuLinks}
        profile={profile}
        isLoggedIn={isLoggedIn}
        location={location}
        isAdmin={isAdminUser}
        showMobileBackIcon={showMobileBackIcon}
        onGoBack={onGoBack}
        hideMobileUserMenu={hideMobileUserMenu}
      />
      {showSubHeader &&
        (<SubHeader
          location={location}
          pageContext={pageContext}
          className={classes.subHeader}
          capitalizeCrumbs={capitalizeCrumbs}
        >
          {subHeaderChildren}
        </SubHeader>)}
      <Grid container className={classes.main} >
        {children}
      </Grid>
      { isLoginPage ? '' :
        <Footer
          menuLinks={renderData.site.siteMetadata.footerMenuLinks}
          isLoggedIn={isLoggedIn}
          termsAndPolicy={renderData.site.siteMetadata.termsAndPolicy}
        ></Footer>}
    </Grid>
  );
};

/*
  Los siguiente campos ya no están en uso, se dejan comentados
  por si hay que volver a utilizarlos en el futuro:
  onlyLoggedIn
  rolesNotAllowed
*/

export const Layout = (props) => {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              subLinks {
                name
                link
              }
            }
            footerMenuLinks {
              name
              link
              subItems {
                name
                link
              }
            }
            termsAndPolicy {
              termsLink
              loggedInTermsLink
              policyLink
            }
          }
        }
      }
    `}
      render={renderData => {
        return (<PureLayout {...props} renderData={renderData} />);
      }
      }
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  subHeaderChildren: PropTypes.node,
  showSubHeader: PropTypes.bool,
};

Layout.defaultProps = {
  showSubHeader: false,
};

export default Layout;
