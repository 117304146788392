/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  decorator: {
    borderBottom: `1px solid ${theme.palette.grayScale.g400}`,
    width:'100%',
    margin:'auto'
  }
}));


const FooterDecorator = () => {
  const classes = useStyles();

  return (
    <Box className={classes.decorator}/>
  );
};



export default FooterDecorator;
