
import { useQuery } from '@apollo/react-hooks';
import { goToIngresar } from '../../api/navigation';
import { GET_USER_AUTH } from '../../queries/getUserAuth';

const useIsLoggedUser = () => {
  const { data: authData, loading: loadingLogin } = useQuery(GET_USER_AUTH,{fetchPolicy: 'network-only'});
  return {isLoggedIn: (authData && authData.currentUser !== null) || false, loadingLogin};
}

export default useIsLoggedUser; 

export const checkIfWasLoggedIn = async (isLoggedIn) =>{
  const wasLoggedIn = sessionStorage.getItem('wasLoggedIn') === 'true';
  if(Boolean(isLoggedIn)){
    sessionStorage.setItem('wasLoggedIn','true');
  }
  if(!isLoggedIn && wasLoggedIn){
    sessionStorage.setItem('wasLoggedIn','false');
    goToIngresar();
  }
  if(!isLoggedIn && !wasLoggedIn){
    sessionStorage.setItem('wasLoggedIn','false');
  }
};

