import gql from 'graphql-tag';

export const  GET_USER_AUTH = gql`
  query GetUserAuth {
    currentUser {
      token
      profile {
        id
        firstname
        lastname
        email
        phone
        role_name
      }
    }
  }
`;
